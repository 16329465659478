import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;



const SmallNotification = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState("");

    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'users', auth.currentUser.uid), (doc) => {
            setUser(doc.id)
            console.log(doc.id)
        });
      }, []);

    return(
        <div className="prompts" onClick={()=>navigate('/journals')}>
            <p>Joy and 3 others commented on your recent journal.</p>
            {/* <p>이브가 새로운 스토리를 포스트 했어요!</p>
            <p>올리브가 새로운 질문을 등록했어요.</p> */}
        </div>
)
}
 
export default SmallNotification