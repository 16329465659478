import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useNavigate} from 'react-router-dom'

import {
  getFirestore, collection, onSnapshot,
  addDoc, deleteDoc, doc,
  query, where,
  orderBy, serverTimestamp,
  updateDoc,
  setDoc, Timestamp
} from 'firebase/firestore'

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword, signOut,
  onAuthStateChanged
} from 'firebase/auth'

import { app } from './config/fbConfig';

// pages
import Home from './pages/Home'
import About from './pages/About'
import Products from './pages/Products'
import ProductDetails from './pages/ProductDetails'
import Notifications from './pages/Notifications';
import Journals from './pages/Journals'
import JournalDetails from './pages/JournalDetails'
import Post from './pages/Post'
import Template from './pages/Template'
import Stickers from './pages/Stickers'
import Reports from './pages/Reports'
import SignIn from './pages/SignIn';
import Register from './pages/Register';
import Register2 from './pages/Register2';
import Profile from './pages/Profile';
import Payment from './pages/Payment';
import SearchResult from './pages/SearchResult';
import DailyLog from './pages/DailyLog';
import MoodCheckIn from './pages/MoodCheckIn';

import {FaHome} from 'react-icons/fa';
import {MdPerson, MdNotifications} from 'react-icons/md';
import {CgProfile} from 'react-icons/cg';
import Register3 from './pages/Register3';




const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


function App() {
  // const navigate = useNavigate();
  const [cartIsEmpty] = useState(false)

  const [user, setUser] = useState(null);

    useEffect(() => {

        const unsubAuth = onAuthStateChanged(auth, (user) => {
          console.log('user status changed:', user)
          setUser(user);
        })
      }, []);

  return (
    <BrowserRouter>
      <div className="App"> 
      
      {user? <nav>
          <h1>Universe</h1>
            <Link to="/"><FaHome className="navIcon" /></Link>
            <Link to="/notifications">
              <MdNotifications className="navIcon"/>
            </Link>
            <Link to="/profile">
              {/* <MdPerson className="navIcon"/> */}
              <CgProfile className="navIcon"/>
            </Link>
        </nav> : null
        }

        {!user? <nav>
          <h1>Universe</h1>
            <Link to="/signin">Log in</Link>
            <Link to="/register">Sign up</Link>
        </nav> : null
        } 

        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/searchresult/:id" element={<SearchResult/>}/>
          <Route path="/signin" element={<SignIn/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/register2" element={<Register2/>} />
          <Route path="/register3" element={<Register3/>} />
          <Route path="/notifications" element={<Notifications/>}/>
          <Route path="/products" element={<Products/>} />
          <Route path="/products/:id/*" element={<ProductDetails/>} />
          <Route path="/profile" element={<Profile />}/>
          <Route path="/payment" element={<Payment/>}/> 
          <Route path="/journals" element={<Journals/>}/>
          <Route path="/journals/:id/*" element={<JournalDetails/>} />
          <Route path="/post" element={<Post/>}/>
          <Route path="/template" element={<Template/>}/>
          <Route path="/stickers" element={<Stickers/>}/>
          <Route path="/dailylog" element={<DailyLog/>}/>
          <Route path="/moodcheckin" element={<MoodCheckIn/>}/>
          <Route path="/reports" element={<Reports/>}/>
          <Route 
            path="/checkout"
            element={cartIsEmpty ? <Navigate to="/products" /> : <p>checkout</p>}
          />
        </Routes>
      </div>
      <div>
        <footer>
            <div>
              <p>&copy; Olive Philosophy 2023. All Rights Reserved.</p>
            </div>
          </footer>
      </div>
    </BrowserRouter>
  )
}

export default App