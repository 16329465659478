import React, {useContext, useState, useEffect} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

  import {FaSearch, FaWindowClose} from 'react-icons/fa';
  import {BsFillArrowRightCircleFill} from 'react-icons/bs';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;



const Prompts = () => {
    const navigate = useNavigate()
    const [text, setText] = useState("");
    const [comment, setComment] = useState("");
    const [prompts, setPrompts] = useState("");

    const [promptsModal, setPromptsModal] = useState(false);

    const [promptSelected, setPromptSelected] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState("");

    const [promptIndex, setPromptIndex] = useState(0);

    const days = ['일요일','월요일','화요일','수요일','목요일','금요일','토요일'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    //for posting the date of the journal written 
    const [day, setDay] = useState("");
    const [month, setMonth] = useState('');
    const [numMonth, setNumMonth] = useState("");
    const [year, setYear] = useState('');
    const [day_week, set_Day_week] =useState('');

    const togglePromptsModal = () => {
      setPromptsModal(!promptsModal);
  };

  if(promptsModal) {
    document.body.classList.add('active-modal')
} else {
    document.body.classList.remove('active-modal')
}

  const addPost = async({text,comment, uid, createdAt, day, numMonth, month, year, day_week}) => {
    return new Promise((res, rej) => {
        addDoc(collection(db, "posts"), {
                text, 
                comment, 
                uid, 
                createdAt,
                day,
                numMonth,
                month, 
                year, 
                day_week
            })
            .then(ref => {
                res(ref);
            })
            .catch(error => {
                rej(error);
            });
    });
  };

  const handlePost = async (e) => {
    e.preventDefault();
    const res = await fetch('https://universe-kor-server-b77a12df1993.herokuapp.com/openai/comment', {
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({journal: text}),
        method: 'POST'
    })
    const data = await res.json()

    console.log(data.comment.content);
    setComment(data.comment.content);
    addPost({text : text,  comment : data.comment.content, uid: auth.currentUser.uid, createdAt: serverTimestamp(), day : day, numMonth : numMonth, month : month, year : year, day_week : day_week})
        .then(ref => {
            setText("");
            togglePromptsModal();
            navigate('/');
        })
        .then(()=>console.log('post succededd'))
        .catch(error => {
            alert(error);
        });
  }



    useEffect(() => {

        setDay(new Date().getDate());
        setNumMonth(new Date().getMonth()+1);
        setMonth(months[new Date().getMonth()]);
        setYear(new Date().getFullYear());
        set_Day_week(days[ new Date().getDay() ])
        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setPrompts(doc.data().prompts)
        });
      }, []);
    return(
        <div className="prompts" onClick={togglePromptsModal}>
            <p>3 Prompts prepared for you!</p>
            

{promptsModal && (
                    <div className="modal order">
                        <div onClick={togglePromptsModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>Hello Modal</h2>
                            {/* <ul>
                              { prompts && prompts.map(item =>{
                                return <li>
                                  <span>{item}</span>
                                </li>
                              })}
                            </ul> */}
                            <p>{prompts[promptIndex]}</p>
                            <form onSubmit={handlePost}>
                                <textarea type="text" placeholder="Enter your journal entry!" value={text} onChange={(e)=>setText(e.target.value)} required/>
                                <input type="submit" value="Post" />
                            </form>
                            
                            <FaWindowClose className="close-modal" onClick={togglePromptsModal}/>
                            <BsFillArrowRightCircleFill/>
                            
                        </div>
                    </div>
            )}
        </div>
)
}
 
export default Prompts

