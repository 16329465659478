import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

const auth = getAuth(app);

const db = getFirestore(app);  



export default function Register3() {
    const navigate = useNavigate()
    const [mbti, setMbti] = useState("");
    


    return (
        <div className="authContainer">
            <p>당신의 아지트에 돌아오신 것을 환영합니다!</p>
            <form onSubmit={console.log('**')}>
                <input type="text" placeholder="당신의 MBTI는?..." value={mbti} onChange={(e)=>setMbti(e.target.value)} required/>
                <input type="submit" value="제출" />
            </form>

            <div>이미 유니버스 계정이 있으신가요?</div>
            <a href="/signin">로그인</a>
            <div className="stretch"></div>
        </div>
    )
}
