import React, {useContext, useState, useEffect} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import {FaWindowClose} from 'react-icons/fa';

const auth = getAuth(app);

const db = getFirestore(app);  



const Post = () => {
    const navigate = useNavigate()
    const [text, setText] = useState("");
    const [comment, setComment] = useState("");

    const [postModal, setPostModal] = useState(false);

    const days = ['일요일','월요일','화요일','수요일','목요일','금요일','토요일'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    //for posting the date of the journal written 
    const [day, setDay] = useState("");
    const [month, setMonth] = useState('');
    const [numMonth, setNumMonth] = useState("");
    const [year, setYear] = useState('');
    const [day_week, set_Day_week] =useState('');

    //user information
    const [vec, setVec] = useState([]);
    const [svec, setSvec] = useState([]);
    const [basicStatusVec, setBasicStatusVec] = useState([]);
    const [level, setLevel] = useState("");

    //작성한 일기 handle위해
    const [journalSituation, setJournalSituation] = useState("");
    const [journalEmotion, setJournalEmotion] = useState("");



    const toggleModal = () => {
        setPostModal(!postModal);
    };

    if(postModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }
    const addPost = async({text,comment, situation, emotion, uid, createdAt, day, numMonth, month, year, day_week}) => {
        return new Promise((res, rej) => {
            addDoc(collection(db, "posts"), {
                    text, 
                    comment, 
                    situation,
                    emotion,
                    uid, 
                    createdAt,
                    day,
                    numMonth,
                    month, 
                    year, 
                    day_week
                })
                .then(ref => {
                    res(ref);
                })
                .catch(error => {
                    rej(error);
                });
        });
    };

    const handlePost = async (e) => {
        e.preventDefault();
        const res = await fetch('https://server-kor-15c298be8f81.herokuapp.com/openai/comment', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data = await res.json()

        console.log(data.comment.content);
        setComment(data.comment.content);

        const res2 = await fetch('https://server-kor-15c298be8f81.herokuapp.com/openai/classify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data2 = await res2.json()
        console.log(data2.classifyResult.content);

        const res3 = await fetch('https://server-kor-15c298be8f81.herokuapp.com/openai/emotionclassify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data3 = await res3.json()
        console.log(data3.classifyResult.content);
        addPost({text : text,  comment : data.comment.content, situation : data2.classifyResult.content, emotion:data3.classifyResult.content, uid: auth.currentUser.uid, createdAt: serverTimestamp(), day : day, numMonth : numMonth, month : month, year : year, day_week : day_week})
            .then(ref => {
                setText("");
                toggleModal();
                navigate('/');
            })
            .then(()=>console.log('post succededd'))
            .catch(error => {
                alert(error);
            });
    }

    useEffect(() => {
        setDay(new Date().getDate());
        setNumMonth(new Date().getMonth()+1);
        setMonth(months[new Date().getMonth()]);
        setYear(new Date().getFullYear());
        set_Day_week(days[ new Date().getDay() ])
    }, []);

    return ( 
        <div>
            <div className="postTrigger" onClick={toggleModal}>
                <div className="categoryContainer">
                    <div className="smallButton1">Daily Journal</div>
                    <div className="smallButton1" onClick={()=>navigate('/moodcheckin')}>Mood Check In</div>
                    <div className="smallButton1" onClick={()=>navigate('/dailylog')}>Log</div>
                </div>

              <div className="holder">
                <p>Enter your journal entry!</p>
              </div>

              <div className="postTriggerFooter">
                <div className="smallButton">Delete</div>
                <div className="smallButton">Post</div>
              </div>
          </div>

          {postModal && (
                    <div className="modal">
                        <div onClick={toggleModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>{year}년 {numMonth}월 {day}일 {day_week}</h2>
                            <div className="smallButton1">Daily Journal</div>
                            
                            <form onSubmit={handlePost}>
                                <textarea type="text" placeholder="Enter your journal entry!" value={text} onChange={(e)=>setText(e.target.value)} required/>
                                <input type="submit" value="Post" />
                            </form>
                            {/* <button className="close-modal" onClick={toggleModal}>
                            CLOSE
                            </button> */}
                            <FaWindowClose className="close-modal" onClick={toggleModal}/>
                        </div>
                    </div>
            )}
        </div>
     );
}
 
export default Post