import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useParams} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;

export default function SearchResult() {
  const { id } = useParams()
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState("");

  const ResultFetch = async() => {
            const res = await fetch('https://server-kor-15c298be8f81.herokuapp.com/openai/search', {
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data = await res.json()

        console.log(data.searchResult.content);
        setResult(data.searchResult.content);
        setPending(false);
  }
  
  useEffect(() => {
        ResultFetch();

  }, []);

  return (
    <div className="containerBasic">
      <div className="searchQuery">
        <p>{id}</p>
      </div>

      <div className="searchResult">
        <img src={JoyStory} alt="olive" />
        {pending? <p>결과를 로딩중입니다. 잠시만 기다려주세요....</p> : null}
        <p>{result}</p>
      </div>
    </div>
  )
}


