import {initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyAv97zJHbGPvD0ctFBXP72hmEiA9Grs5hE",
  authDomain: "aijournaluniverse.firebaseapp.com",
  projectId: "aijournaluniverse",
  storageBucket: "aijournaluniverse.appspot.com",
  messagingSenderId: "1065559891184",
  appId: "1:1065559891184:web:5158e1903c2beed0df7635",
  measurementId: "G-D1EB0351Y9"
};


const app = initializeApp(firebaseConfig);

export {app};