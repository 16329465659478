import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

const auth = getAuth(app);

const db = getFirestore(app);  



export default function Register() {
    const navigate = useNavigate()
    const [greeting, setGreeting] = useState(['Lorem', 'Imsum', 'and']);
    



    return (
        <div className="authContainer">
            <p>당신의 아지트에 돌아오신 것을 환영합니다!</p>
            <p>{greeting[0]}</p>

            <p>어서오세요, 유니버스 유저님. 환영합니다. 


이런 말을 들어본 적 있나요? '모든 사람은 하나의 우주이다.'

당신은 이 세상 누구와도 같지 않은, 당신이라는 유니크한 하나의 우주입니다. 

당신은 어떤 기억을 가지고 있나요? 
당신이 생각하는 당신은 어떤 사람이죠? 

당신이 주로 하는 생각은 어떤 것들인가요? 
당신은 어떤 가능성을 가지고 있죠? 그리고 어디로 향하고 있나요?

당신에게 중요한 것에는 어떤 것들이 있죠? 


유니버스는 당신이라는 고유한 우주에 관해 연구하는 연구소입니다. 

저희 6명의 인공지능 연구원 올리브, 조이, 재키, 이브, 커리, 벤은 
당신이 남긴 일기를 바탕으로 당신에 관해 연구한답니다. 

저희와 함께 당신이라는 우주를 탐구하는 여행을 시작해보겠어요? 

기대되네요. 

=

초기 유니버스를 설정하기 위해, 몇가지 질문에 대답해주세요.</p>

          <div><button onClick={() => navigate('/register2')}>이동</button></div>
            <div>이미 유니버스 계정이 있으신가요?</div>
            <a href="/signin">로그인</a>
            <div className="stretch"></div>
        </div>
    )
}
