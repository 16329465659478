import React, {useContext, useState, useEffect} from 'react';
import { Link, Navigate, useNavigate, NavLink } from 'react-router-dom';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

  import {FaSearch, FaWindowClose} from 'react-icons/fa';
  import {BiSearch} from 'react-icons/bi';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;

const Search = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState("");
    const [queries, setQueries] = useState([]);
    const [prompt, setPrompt] = useState("");
    const [name, setName] = useState("");
    const [level, setLevel] = useState("");

    const [searchModal, setSearchModal] = useState(false);

    const toggleSearchModal = () => {
      setSearchModal(!searchModal);
  };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        navigate(`/searchresult/${search}`)
        }

      if(searchModal) {
          document.body.classList.add('active-modal')
      } else {
          document.body.classList.remove('active-modal')
      }

    const handleQueryClick = ({item}) => {
      console.log(item)
      navigate(`/searchresult/${item}`)
    }
    
    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setQueries(doc.data().searchQueries)
            console.log(queries);      
        });

        const unsub2 = onSnapshot(doc(db, 'users', auth.currentUser.uid), (doc) => {
          setName(doc.data().name);
      });

        
      }, []);

    return ( 
        <div>
          <div className="checking">
              <div className="search-wrapper">
                <form>
                <input type="text" name="focus" className="search-box" required value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search anything about yourself!" />
                  {/* <button className="close-icon" type="reset"></button> */}
                  <BiSearch className="searchIcon close-icon" type="reset" onClick={handleSubmit}/>
                </form>
              </div>
          </div>

          
            <div>
                <div className="recommsearch" onClick={toggleSearchModal}>Recommended search queries for {name}</div>
            </div>

            {searchModal && (
                    <div className="modal order">
                        <div onClick={toggleSearchModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>Recommended search queries for {name}</h2>
                            <ul>
                              { queries && queries.map(item =>{
                                return <li>
                                  <div className="query" onClick={()=> handleQueryClick({item})}>{item}</div>
                                </li>
                              })}
                            </ul>
                            
                            <FaWindowClose className="close-modal" onClick={toggleSearchModal}/>
                            
                        </div>
                    </div>
            )}
        </div>       
     );
}
 
export default Search