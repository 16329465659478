import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  
import axios from 'axios';

const auth = getAuth(app);

const db = getFirestore(app);  




export default function Payment() {
    const [cardNumber, setCardNumber] = useState("");
    const [expiry, setExpiry] =useState("");
    const [birth, setBirth] = useState("");
    const [pwd2Digit, setPwd2Digit] = useState("");
    const [customer_uid, setCustomer_uid] = useState("gildong_0001_1234");

    const navigate = useNavigate()
    const handleLogout = ()=> {
        signOut(auth)
            .then(() => {
            console.log('user signed out')
            navigate('/signin')
            console.log(auth.currentUser);
            })
            .catch(err => {
            console.log(err.message)
            })
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const { cardNumber, expiry, birth, pwd2Digit, customer_uid } = this.state;
        axios({
          // 예: https://www.myservice.com/subscription/issue-billing
          url: "{빌링키 발급 요청을 받을 서비스 URL}", 
          method: "post",
          data: {
            cardNumber,
            expiry,
            birth,
            pwd2Digit,
            customer_uid,
          }
        }).then(rsp => {
      
        });
      };

   
      return (
        <div className="container2">
          <div className="profilenav">
            <nav>
              <ul>
                <li onClick={() => navigate('/profile')}>프로필</li>
                <li onClick={() => navigate('/payment')}>결제</li>
                <li>하트력 충전</li>
                <li onClick={()=>handleLogout()}>로그아웃</li>
              </ul>
            </nav>
          </div>
            <p>//페이먼트 셀렉터 스크린 이전에 만들어야함. 그거 클릭하면 이렇게 오게.</p>
            <form onSubmit={handleFormSubmit}>
            <label>
                카드 번호
                <input type="text" name="cardNumber" value={cardNumber} onChange={(e)=>setCardNumber(e.target.value)} />
            </label>
            <label>
                카드 유효기간
                <input type="text" name="expiry" value={expiry} onChange={(e)=>setExpiry(e.target.value)} />
            </label>
            <label>
                생년월일
                <input type="text" name="birth" value={birth} onChange={(e)=>setBirth(e.target.value)} />
            </label>
            <label>
                카드 비밀번호 앞 두자리
                <input type="text" name="pwd2Digit" value={pwd2Digit} onChange={(e)=>setPwd2Digit(e.target.value)} />
            </label>
            <input type="submit" value="결제하기" />
            </form>


        </div>
      )
}

