import React, { useContext, useState, useEffect } from 'react';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
} from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
} from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

import {FaWindowClose} from 'react-icons/fa';
import {BsFillArrowRightCircleFill} from 'react-icons/bs';

const auth = getAuth(app);

const db = getFirestore(app);

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;




const Bots = () => {
    const [olive, setOlive] = useState([]);
    const [joy, setJoy] = useState([]);
    const [jackie, setJackie] = useState([]);
    const [eve, setEve] = useState([]);
    const [kerry, setKerry] = useState([]);
    const [ben, setBen] = useState([]);

    const [OlivePostModal, setOlivePostModal] = useState(false);
    const [JoyPostModal, setJoyPostModal] = useState(false);
    const [JackiePostModal, setJackiePostModal] = useState(false);
    const [EvePostModal, setEvePostModal] = useState(false);
    const [KerryPostModal, setKerryPostModal] = useState(false);
    const [BenPostModal, setBenPostModal] = useState(false);

    const [oliveIndex, setOliveIndex] = useState(0);
    const [joyIndex, setJoyIndex] = useState(0);
    const [jackieIndex, setJackieIndex] = useState(0);
    const [eveIndex, setEveIndex] = useState(0);
    const [kerryIndex, setKerryIndex] = useState(0);
    const [benIndex, setBenIndex] = useState(0);

    const toggleOliveModal = () => {
        setOlivePostModal(!OlivePostModal);
    };

    const toggleJoyModal = () => {
        setJoyPostModal(!JoyPostModal);
    };

    const toggleJackieModal = () => {
        setJackiePostModal(!JackiePostModal);
    };

    const toggleEveModal = () => {
        setEvePostModal(!EvePostModal);
    };

    const toggleKerryModal = () => {
        setKerryPostModal(!KerryPostModal);
    };

    const toggleBenModal = () => {
        setBenPostModal(!BenPostModal);
    };

    if(OlivePostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(JoyPostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(JackiePostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(EvePostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(KerryPostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }
    if(BenPostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setOlive(doc.data().olive)
            setJoy(doc.data().joy)
            setJackie(doc.data().jackie)
            setEve(doc.data().eve)
            setKerry(doc.data().kerry)
            setBen(doc.data().ben)

        });


    }, []);
    
    return(
        <div>
            <div className="bots">
                <div className="botIndi">
                    <img src={OliveStory} onClick={toggleOliveModal} alt="olive" />
                    <p>Olive</p>
                    <div className="botnoti">6</div>
                </div>
                <div className="botIndi">
                    <img src={JoyStory} onClick={toggleJoyModal} alt="joy" />
                    <p>Joy</p>
                    <div className="botnoti">6</div>
                </div>

                <div className="botIndi">
                    <img src={JackieStory} onClick={toggleJackieModal} alt="jackie" />
                    <p>Jackie</p>
                    <div className="botnoti">3</div>
                </div>
          
                <div className="botIndi">
                    <img src={EveStory} onClick={toggleEveModal} alt="eve" />
                    <p>Eve</p>
                    <div className="botnoti">5</div>
                </div>
                <div className="botIndi">
                    <img src={KerryStory} onClick={toggleKerryModal} alt="kerry" />
                    <p>Kerry</p>
                    <div className="botnoti">6</div>
                </div>
                <div className="botIndi">
                    <img src={BenStory} onClick={toggleBenModal} alt="ben" />
                    <p>Ben</p>
                    <div className="botnoti">6</div>
                </div>
         
            </div>

            

            

            


            {OlivePostModal && (
                    <div className="modal">
                        <div onClick={toggleOliveModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>Olive</h2>
                            <img src={OliveStory} alt="olive" />
                            
                            <p>
                            {olive[0]}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleOliveModal}/>
                            <BsFillArrowRightCircleFill/>
                        </div>
                        
                    </div>
            )}

            {JoyPostModal && (
                    <div className="modal">
                        <div onClick={toggleJoyModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>Joy</h2>
                            <img src={JoyStory} alt="olive" />
                            <p>
                            {joy[0]}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleJoyModal}/>
                            <BsFillArrowRightCircleFill/>
                        </div>
                        
                    </div>
            )}

{JackiePostModal && (
                    <div className="modal">
                        <div onClick={toggleJackieModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>Jackie</h2>
                            <img src={JackieStory} alt="olive" />
                            
                            <p>
                            {jackie[0]}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleJackieModal}/>
                            <BsFillArrowRightCircleFill/>
                        </div>
                    </div>
            )}

{EvePostModal && (
                    <div className="modal">
                        <div onClick={toggleJoyModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>Eve</h2>
                            <img src={EveStory} alt="olive" />
                            
                            <p>
                            {eve[0]}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleEveModal}/>
                            <BsFillArrowRightCircleFill/>
                        </div>
                    </div>
            )}

{KerryPostModal && (
                    <div className="modal">
                        <div onClick={toggleJoyModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>Kerry</h2>
                            <img src={KerryStory} alt="olive" />
                            
                            <p>
                            {kerry[0]}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleKerryModal}/>
                            <BsFillArrowRightCircleFill/>
                        </div>
                    </div>
            )}

{BenPostModal && (
                    <div className="modal">
                        <div onClick={toggleJoyModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>Ben</h2>
                            <img src={BenStory} alt="olive" />
                            
                            <p>
                            {ben[0]}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleBenModal}/>
                            <BsFillArrowRightCircleFill/>
                        </div>
                        
                    </div>
            )}
            {/* <div className="botIndividual">
                <p>Olive stories</p>
                <ul>
                    {olive && olive.map(item => {
                        return <li>
                            <span>{item}</span>
                        </li>
                    })}
                </ul>
            </div>

            <div className="botIndividual">
                <p>Joy stories</p>
                <ul>
                    {joy && joy.map(item => {
                        return <li>
                            <span>{item}</span>
                        </li>
                    })}
                </ul>
            </div>

            <div className="botIndividual">
                <p>Jackie stories</p>
                <ul>
                    {jackie && jackie.map(item => {
                        return <li>
                            <span>{item}</span>
                        </li>
                    })}
                </ul>
            </div>

            <div className="botIndividual">
                <p>Eve stories</p>
                <ul>
                    {eve && eve.map(item => {
                        return <li>
                            <span>{item}</span>
                        </li>
                    })}
                </ul>
            </div>


            <div className="botIndividual">
                <p>Kerry stories</p>
                <ul>
                    {kerry && kerry.map(item => {
                        return <li>
                            <span>{item}</span>
                        </li>
                    })}
                </ul>
            </div>

            <div className="botIndividual">
                <p>Ben stories</p>
                <ul>
                    {ben && ben.map(item => {
                        return <li>
                            <span>{item}</span>
                        </li>
                    })}
                </ul>
            </div> */}
        </div>
)
}
 
export default Bots