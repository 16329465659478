import React, {useContext, useState, useEffect} from 'react';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

  import Brain from "../assets/images/brain2.png"

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;

export default function Templates() {
    const [templates, setTemplates] = useState([]);

    // useEffect(() => {

    //     const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
    //         setTemplates(doc.data().templates)
            
    //     });
    //   }, []);
  return (
    <div className="templates">
        <p>연구원들이 당신을 위해 준비한 오늘 저녁의 일기 템플릿들을 살펴보세요!</p>
            <img src={Brain} alt="product" />
        <ul>
        { templates && templates.map(item =>{
            return <li>
            <span>{item}</span>
            </li>
        })}
        </ul>

        <div>
          <div className="temp1">
            템1
          </div>
          <div className="temp2">
            템2
          </div>
          <div className="temp3">
            템3
          </div>
          <div className="temp4">
            템4
          </div>
          <div className="temp5">
            템5
          </div>
          <div className="temp6">
            템6
          </div>
          <div className="temp7">
            템7
          </div>
          <div className="temp8">
            템8
          </div>
        </div>
    </div>
  )
}
