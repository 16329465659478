import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useParams, useNavigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;

export default function JournalDetails() {
    const navigate = useNavigate()
    const [journal, setJournal] = useState([]);
    const { id } = useParams()

    const handleDelete = (id) => {
      navigate('/')
      const docRef = doc(db, 'posts', id)

      deleteDoc(docRef)
      .then(()=> console.log("delete succedeed"))
      .catch(error => {
          alert(error);
      });

    }


    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'posts', id), (doc) => {
            setJournal(doc.data())
            console.log('**')
            console.log(doc.data())
        });
      }, []);
    
    return (
        <div className="containerBasic">
          <h2>{journal.year}년 {journal.numMonth}월 {journal.day}일 {journal.day_week}</h2>
          <p>{journal.text}</p>
          <div className="comments">
            <img src={JoyStory} alt="olive" />
            <p>{journal.comment}</p>
            
          </div>
          
          <div>
            <p>{journal.situation}</p>
            <p>{journal.emotion}</p>
          </div>
          
          <div>
            <button onClick={()=> handleDelete(id)}>글 삭제</button>
          </div>
        </div>
  )
}

