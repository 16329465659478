import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useParams, useNavigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

import {FaRegSmile} from 'react-icons/fa';
import {RiEmotionLaughLine, RiEmotionNormalLine, RiEmotion2Line, RiEmotionUnhappyLine} from 'react-icons/ri';
import {BsFillJournalBookmarkFill} from 'react-icons/bs'
import {MdCardTravel, MdFastfood, MdWorkOutline} from 'react-icons/md';
import {GrGamepad} from 'react-icons/gr';
import {AiOutlineShopping} from 'react-icons/ai';
import {BiBed} from 'react-icons/bi';
import {TiWeatherPartlySunny} from 'react-icons/ti';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;


export default function MoodCheckIn() {
    const [timeInterval, setTimeInterval] = useState("밤");

    return (
        <div className="container2">
            <p>Mood Check in</p>
            <p>안녕하세요! 멋진 유저님. 오늘 {timeInterval} 당신의 기분은 어떤가요?</p>

            <div className="emotions">
                <RiEmotionLaughLine/>
                <FaRegSmile/>
                <RiEmotion2Line/>
                <RiEmotionNormalLine/>
                <RiEmotionUnhappyLine/>
            </div>

            <br/>

            <p>좋네요! 무엇이 당신의 {timeInterval}을 super awesome! 하게 만들어주고 있나요?</p>

            <div className="activities">
                <BsFillJournalBookmarkFill/>
                <MdWorkOutline/>
                <MdFastfood/>
                <GrGamepad/>
                <BiBed/>
                <TiWeatherPartlySunny/>
                <MdCardTravel/>
                <AiOutlineShopping/>
            </div>

            <br/>
            <p>… 그리고 그것에 대한 당신의 기분은?(…and how are you feeling about this?)
	행복해요/축복받은듯해요/좋아요/ 혼란스러워요/지루해요/어색 혹은 이상해요 / 스트레스받아요/화가나요/걱정돼요/다운돼요/기타
	happy/blessed/good/lucky/confused/bored/awkward/stressed/angry/anxious/down/etc

그런 기분이 들게 하는 상황의 범주를 골라본다면?</p>
        </div>

  )
}
