import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

const auth = getAuth(app);

const db = getFirestore(app);  

export default function Profile() {
    const navigate = useNavigate()
    const handleLogout = ()=> {
        signOut(auth)
            .then(() => {
            console.log('user signed out')
            navigate('/signin')
            console.log(auth.currentUser);
            })
            .catch(err => {
            console.log(err.message)
            })
    }



  return (
    <div className="container3">
      <div className="profilenav">
        <nav>
          <ul>
            <li>프로필</li>
            <li onClick={() => navigate('/payment')}>결제</li>
            <li>하트력 충전</li>
            <li onClick={()=>handleLogout()}>로그아웃</li>
          </ul>
        </nav>
      </div>
      <p>유니버스 레벨 : 0</p>
      <p>하트력</p>
      <a href="">하트력은 어떻게 충전시키나요?</a>
      <br/>
      <button>하트력 충전</button>
      <br/>
      <br/>
      <h2>This is payment page</h2>

      <div className="payment">
        <button onClick={() => navigate('/payment')}>Pay now</button> 
      </div>
      <br/>
      <button onClick={() => navigate('/')}>Back to home</button>


      
    </div>
  )
}
