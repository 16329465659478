import React from 'react'

export default function Notifications() {
  return (
    <div className="containerBasic">
        <div className="card">This is notification example</div>
        <div className="card">This is notification example</div>
        <div className="card">This is notification example</div>
        <div className="card">This is notification example</div>
        <div className="card">This is notification example</div>
        <div className="card">This is notification example</div>
    </div>
  )
}
